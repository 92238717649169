@import '../../styles/vars.css';

.footer {
  padding: 15px 0;

  width: 100%;
  min-height: 60px;
  
  display: flex;
  align-items: center;

  background-color: var(--color-primary-bg);
}

.footer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  
  font-size: calc(var(--fz-s) - 2px);
}

.devLink {
  display: flex;
  gap: 5px;
  & a {
    color: var(--base-dark-color);
    box-shadow: 0 1px 2px -1px var(--base-dark-color);
  }
}

@media (min-width: 1024px) {
  .footer__container {
    flex-direction: row;
    justify-content: space-between;

    font-size: var(--fz-s)
  }
}


@media (min-width: 1260px) {
  .footer {
    min-height: 80px;
  }
}
