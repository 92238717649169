@import '../../styles/vars.css'; 

.intro {
  padding-bottom: 40px;

  background-color: var(--color-primary-bg);
  font-family: var(--ff-tilda);
  color: var(--color-primary-text);
}

.logo {
  padding: 20px 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.contacts {
  padding: 10px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.phone__title {
  margin: 0;
  margin-bottom: 10px;

  display: none;

  font-size: var(--fz-m);
}

.phone__number {
  margin: 0;

  font-size: var(--fz-m);
  font-weight: 600;
  text-decoration: none;
  color: var(--color-primary-text);
}

.openingHours__text {
  margin: 0;

  font-size: calc(var(--fz-s) - 2px);
}

@media (min-width: 768px) {
  .intro {
    padding: 40px 0;
  }

  .intro__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo img {
    width: 250px;
  }

  .contacts {
    text-align: right;
    align-items: flex-end;
  }

  .phone__title {
    display: block;
  }
}

@media (min-width: 1260px) {
  .logo img {
    width: 350px;
  }

  .contacts {
    gap: 10px;
  }

  .phone__title {
    margin-bottom: 20px;
  }

  .phone__number {
    font-size: var(--fz-l);
  }

  .openingHours__text {
    font-size: var(--fz-s);
  }
}
