@font-face {
  font-family: "Tilda Sans";
  src:
    url("../fonts/TildaSans-Light/TildaSans-Light.woff2") format("woff2"),
    url("../fonts/TildaSans-Light/TildaSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Tilda Sans";
  src:
    url("../fonts/TildaSans-Regular/TildaSans-Regular.woff2") format("woff2"),
    url("../fonts/TildaSans-Regular/TildaSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Tilda Sans";
  src:
    url("../fonts/TildaSans-Medium/TildaSans-Medium.woff2") format("woff2"),
    url("../fonts/TildaSans-Medium/TildaSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Tilda Sans";
  src:
    url("../fonts/TildaSans-Semibold/TildaSans-Semibold.woff2") format("woff2"),
    url("../fonts/TildaSans-Semibold/TildaSans-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Tilda Sans";
  src:
    url("../fonts/TildaSans-Bold/TildaSans-Bold.woff2") format("woff2"),
    url("../fonts/TildaSans-Bold/TildaSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
