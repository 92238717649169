@import '../../styles/vars.css'; 

.callback {
  padding: 20px;
  width: 350px;
  min-height: 270px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: var(--ff-tilda);
  
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  z-index: 10;
}

.callback__submitCover {
  height: 100%;
  min-height: 150px;
  
  width: 100%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  color: var(--base-light-color);
}

.callback__submitTitle {
  font-weight: 700;
  user-select: none;
}

.formHeader {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 5px;
}

.formHeader__title,
.formHeader__subtitle {
  margin: 0;

  font-size: var(--fz-s);
  color: var(--base-light-color);
  text-align: left;
}

.formHeader__title {
  font-size: calc(var(--fz-s) + 3px);
  font-weight: 800;
}

.form {
  width: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  
  & label {
    padding: 10px 0;
    
    display: flex;
    flex-direction: column;
    
    user-select: none;

    & small {
      color: var(--color-red);
      font-size: var(--fz-s);
    }
  }

  & .form__agreementLabel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;

    column-gap: 5px;
    font-size: calc(var(--fz-s) - 3px);
    color: var(--base-light-color);

    & button {
      padding: 0;

      line-height: 1;
      color: var(--color-light-blue);
      font-size: calc(var(--fz-s) - 3px);

      &:hover {
        color: cadetblue !important;
      }
    }

    & input {
      width: auto;
    }

    & .form__error {
     grid-column: 1/-1;
     color: var(--color-red);
    }
  }

  & input,
  & textarea {
    padding: 10px;

    width: 100%;
    
    display: block;
    box-sizing: border-box;
    
    font-size: var(--fz-s);
    line-height: 1;
    font-family: var(--ff-tilda);
    
    border-radius: 6px;
    outline: none;
    border: 1px solid var(--color-grey);
    
    &::placeholder {
      color: var(--color-grey);
    }
  }
  
  & input[type="submit"] {
    margin-bottom: 0;
    padding: 10px 30px;

    width: 100%;
    
    font-size: var(--fz-s);
    font-family: var(--ff-tilda);
    font-weight: 500;
    color: var(--base-light-color);
    
    border: none;
    border-radius: 6px;
    background-color: var(--color-red);
    background: linear-gradient(to right bottom, var(--color-red), var(--color-orange));
    box-shadow: 0 3px 5px 0px rgba(var(--base-dark-color), 0.3);
    
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  
  
    &:hover {
      color: var(--base-light-color);

      background-color: var(--color-red-light);
      opacity: 0.9;
    }

    &:disabled {
      pointer-events: none;
      user-select: none;
    }
  }

  & textarea {
    resize: none;
    height: 80px;
  }
}

.form__inputTitle {
  margin-bottom: 10px;
}

.form__error {
  color: var(--color-red);
 }

.submitButton {
  &:disabled {
    opacity: 0.6;
  }
}
