@import '../../styles/vars.css';

.questions {
  padding: 30px 0;

  background-color: var(--color-primary-bg);
  color: var(--color-primary-text);
}

.questions__header {
  margin: 0;
  margin-bottom: 20px;

  font-family: var(--ff-tilda);
  font-weight: 600;
  text-align: center;
}

.questions__question {
  margin: 0;

  font-size: var(--fz-s);
}

.questions__answer {
  margin: 0;

  font-size: var(--fz-s);
}

@media (min-width: 768px) {
  .questions {
    padding: 40px 0;
  }

  .questions__question {
    font-size: calc(var(--fz-m) - 2px);
  }
}

@media (min-width: 1024px) {
  .questions__question {
    font-size: var(--fz-m);
  }

  .questions__answer {
    font-size: calc(var(--fz-m) - 2px);
  }
}
