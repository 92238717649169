@import '../../styles/vars.css'; 

.about {
  padding: 40px 0;

  font-family: var(--ff-tilda);
  color: var(--color-primary-text);

  background-color: var(--base-light-color);
}

.about__headerTitle {
  margin: 0;
  margin-bottom: 15px;

  font-size: var(--fz-m);
  line-height: 1;
}

.about__headerSubtitle {
  margin: 0;

  font-size: calc(var(--fz-s) + 1px);
  line-height: 1;
}

@media (min-width: 500px) {
  .about__headerSubtitle {
    font-size: calc(var(--fz-s) + 2px);
  }
}

@media (min-width: 768px) {
  .about__headerTitle {
    margin-bottom: 20px;

    font-size: calc(var(--fz-m) + 2px);
  }

  .about__headerSubtitle {
    font-size: var(--fz-m);
  }
}

@media (min-width: 1024px) {
  .about {
    padding: 80px 0;
  }
}
