@import '../../styles/vars.css';

.price {
  padding: 30px 0;

  font-family: var(--ff-tilda);

  background-color: var(--color-primary-bg);
}

.price__listContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.price__imageWrapper {
  display: none;
  position: relative;

  & img {
    width: 100%;
    height: 100%;
    aspect-ratio: 2/1;
  }

  & form {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.price__header {
  margin: 0;
  margin-bottom: 10px;

  font-size: calc(var(--fz-m) - 2px);
  font-weight: 600;
  text-align: center;
}

.price__list {
  margin: 0;
  padding: 0;
}

.price__item {
  padding: 10px 0;

  min-width: fit-content;

  display: flex;
  align-items: center;

  font-size: var(--fz-s);
}

.price__subtitle {
  margin: 0;
  margin-bottom: 10px;

  font-size: calc(var(--fz-m) - 2px);
  font-weight: 500;
}

.price__itemTitle {
  margin: 0;

  display: flex;
  align-items: center;
  gap: 5px;

  flex-shrink: 0;
}

.price__itemPrice {
  margin: 0;
  margin-left: 10px;

  flex-shrink: 0;
}

.price__dots {
  margin: 0 10px;

  flex-grow: 1;

  border-bottom: 1px dotted var(--base-dark-color);
}

.popover {
  padding: 0 6px;
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 500;

  cursor: pointer;
  border-radius: 50%;
  background-color: var(--base-light-color);
}

@media (min-width: 768px) {
  .price {
    padding: 40px 0;
  }

  .price__header {
    margin-bottom: 40px;

    font-size: var(--fz-m);
  }

  .price__item {
    font-size: var(--fz-m);
  }

  .price__subtitle {
    margin-bottom: 20px;
    
    font-size: var(--fz-m);
    font-weight: 600;
  }
}

@media (min-width: 1024px) {
  .price__innerContainer {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 40px;
    justify-items: stretch;
  }

  .price__imageWrapper {
    display: block;
  }
}
