@import '../../styles/vars.css';

.privacyButton {
  display: inline-block;
  background-color: transparent;
  border: none;
  box-shadow: none;

  font-size: var(--fz-s);

  &:hover {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.7) !important;
  }
}

.scrollButtonWrapper {
  margin-bottom: 10px;

  text-align: center;
}



@media (min-width: 1024px) {
  .scrollButtonWrapper {
    display: none;
  }
}
