@import '../../styles/vars.css';

.hero {
  min-height: 400px;

  font-family: var(--ff-tilda);
  
  background: linear-gradient(var(--color-primary-bg) 50%, var(--base-light-color));
}

.hero__container {
  min-height: 400px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-accent-1);
  /* background-image: url('../../assets/hero_img.webp');
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat; */

  & img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
}

@media (min-width: 1024px) {
  .hero__container {
    padding: 0 80px !important;

    min-height: 500px;

    justify-content: flex-start;
  }
}

@media (min-width: 1260px) {
  .hero {
    padding-bottom: 60px;
  }

  .hero__container {
    box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.5);
  }
}

.srcInput__wrapper {
  position: absolute;

  z-index: 10;
  right: 0;
  top: 0;
}
