@import '../../styles/vars.css'; 

.contacts {
  padding: 30px 0;

  font-family: var(--ff-tilda);
  background-color: var(--base-light-color);
  color: var(--color-primary-text);
}

.contacts__header {
  margin: 0;
  margin-bottom: 20px;

  font-size: var(--fz-m);
  font-weight: 500;
  text-align: center;
}

.contacts__innerContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contacts__infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contacts__item {
  display: flex;
  align-items: stretch;
  gap: 10px;

  font-size: var(--fz-s);
}

.contacts__item * {
  margin: 0;

  color: var(--color-primary-text);
  line-height: 1;
}

.contacts__map {
  display: flex;
  align-items: center;
  justify-content: center;

  & .map {
    width: 100% !important;
    max-width: 600px;
    
    aspect-ratio: 1 / 1;
  }

  & img {
    width: 100%;
    max-width: 600px;
    
    aspect-ratio: 1 / 1;
  }
}

.contacts__map img {
  width: 100%;
  max-width: 600px;
  
  aspect-ratio: 1 / 1;
}

.socialsWrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  & a {
    font-size: var(--fz-s);

    &:hover {
      background-color: transparent;
    }
  }
}

@media (min-width: 768px) {
  .contacts__header {
    margin-bottom: 40px;
  }

  .contacts__innerContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .contacts__infoWrapper {
    gap: 30px;
  }

  .contacts__item * {
    font-size: calc(var(--fz-m) - 2px);
  }
  .contacts__map img {
    max-width: 500px;
  }
}

@media (min-width: 1024px) {
  .contacts {
    padding: 40px 0;
  }
  .contacts__header {
    font-weight: 600;
  }
}
